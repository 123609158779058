<template>
  <v-form @submit.prevent="$emit('save', item)">
    <v-card>
      <v-card-title class="pa-0">
        <v-tabs v-model="activeTab" class="border-b">
          <v-tab>
            {{ $t('general.project') }}
            <v-icon v-if="hasErrors('project')" color="error" class="ml-1">error</v-icon>
          </v-tab>
          <v-tab>
            {{ $t('general.team') }}
          </v-tab>
          <v-tab v-for="(config, index) in item.configurations" :key="index" color="error">
            {{ $t('general.configuration') }} {{ index + 1 }}
            <v-icon v-if="hasErrors(`configurations.${index}`)" color="error" class="ml-1"
              >error</v-icon
            >
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.title"
                  :error-messages="errors.title"
                  :label="formMixin_getRequiredFieldLabel($t('general.title'))"
                  @input="formMixin_clearErrors('title')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <ContactAutocomplete
                  v-model="item.client_id"
                  :error-messages="errors['client_id']"
                  :initial-item="item.client"
                  show-create-button
                  @input="formMixin_clearErrors('client_id')"
                  @update:initial-item="item.client = $event"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.contract_no"
                  :error-messages="errors.contract_no"
                  :label="$t('documents.labels.contract_no')"
                  @input="formMixin_clearErrors('contract_no')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <BaseDatepickerInput
                  v-model="item.contract_date"
                  :error-messages="errors['contract_date']"
                  :label="$t('projects.labels.contract_date')"
                  @input="formMixin_clearErrors('contract_date')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <BaseDatepickerInput
                  v-model="item.estimated_beginning_at"
                  :error-messages="errors['estimated_beginning_at']"
                  :label="$t('projects.labels.estimated_start')"
                  @input="formMixin_clearErrors('estimated_beginning_at')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <BaseDatepickerInput
                  v-model="item.deadline"
                  :error-messages="errors['deadline']"
                  :label="$t('projects.labels.deadline')"
                  @input="formMixin_clearErrors('deadline')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.estimated_duration"
                  :error-messages="errors.estimated_duration"
                  :label="$t('projects.labels.estimated_duration')"
                  @input="formMixin_clearErrors('estimated_duration')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.estimated_sum"
                  :error-messages="errors.estimated_sum"
                  :label="$t('projects.labels.estimated_sum')"
                  @input="formMixin_clearErrors('estimated_sum')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="item.status"
                  :items="projectStatuses"
                  :error-messages="errors['status']"
                  :label="$t('general.status')"
                  required
                  @change="formMixin_clearErrors('status')"
                />
              </v-col>

              <!--              <v-col cols="12" sm="6">-->
              <!--                <v-text-field-->
              <!--                  v-model="item.sentry_name"-->
              <!--                  :error-messages="errors.sentry_name"-->
              <!--                  :label="$t('projects.labels.sentry_name')"-->
              <!--                  @input="formMixin_clearErrors('sentry_name')"-->
              <!--                />-->
              <!--              </v-col>-->

              <v-col cols="12">
                <v-textarea
                  v-model="item.description"
                  :error-messages="errors.description"
                  :label="$t('projects.labels.description')"
                  rows="1"
                  auto-grow
                  @input="formMixin_clearErrors('description')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="item.is_private"
                  :error-messages="errors.is_private"
                  :label="$t('projects.labels.is_private')"
                  @change="formMixin_clearErrors('is_private')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.warranty_percent"
                  :error-messages="errors.warranty_percent"
                  :label="$t('projects.labels.warranty_percent')"
                  type="number"
                  @input="formMixin_clearErrors('warranty_percent')"
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <ProjectAssigneeMultiSelect v-model="item.assignees" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="$emit('cancel', item)">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn :disabled="disabled" :loading="disabled" color="primary" type="submit" text>
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import clone from 'just-clone';
import formMixin from '@/mixins/form-mixin';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';
import ProjectAssigneeMultiSelect from '@/components/form-fields/ProjectAssigneeMultiSelect';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';

export default {
  name: 'ProjectForm',

  components: {
    BaseDatepickerInput,
    ProjectAssigneeMultiSelect,
    ContactAutocomplete,
  },

  mixins: [formMixin],

  props: {
    project: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTab: 0,
      item: {},
    };
  },

  computed: {
    ...mapState('settings', ['locale']),
    ...mapGetters('projects', ['projectStatuses']),

    configurationFields() {
      return [
        { value: 'is_configured_server_production', type: 'checkbox' },
        { value: 'is_configured_server_staging', type: 'checkbox' },
        { value: 'is_configured_ci_production', type: 'checkbox' },
        { value: 'is_configured_ci_staging', type: 'checkbox' },
        { value: 'is_configured_ci_testing', type: 'checkbox' },
        { value: 'is_configured_ci_code_quality', type: 'checkbox' },
        { value: 'code_quality_score', type: 'number' },
        { value: 'test_coverage_percentage', type: 'number' },
        { value: 'url_git', type: 'text' },
        { value: 'url_chat', type: 'text' },
        { value: 'url_server_production', type: 'text' },
        { value: 'url_server_staging', type: 'text' },
        { value: 'url_code_quality', type: 'text' },
        { value: 'url_db_production', type: 'text' },
        { value: 'url_db_staging', type: 'text' },
        { value: 'url_documentation', type: 'text' },
        { value: 'url_error_analysis_production', type: 'text' },
        { value: 'url_error_analysis_staging', type: 'text' },
        { value: 'url_errors_channel', type: 'text' },
        { value: 'url_monitoring_tool', type: 'text' },
      ];
    },
  },

  created() {
    this.item = clone(this.project);
  },

  methods: {
    hasErrors(tabName) {
      const errors = Object.keys(this.errors);
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].includes(tabName) && this.errors[errors[i]].length) {
          // errors in configurations will always be prefixed with configurations.${index}
          return true;
        }
        if (
          tabName === 'project' &&
          this.errors[errors[i]].length &&
          !errors[i].includes('configurations')
        ) {
          // other errors like client_id, title, are all in the first, project tab.
          // Not the best way to check for errors in tabbed forms, would be good to come up
          // with a more versatile, clearer solution.
          return true;
        }
        // Errors are currently not tracked in team tab.
        // The assumption was, that the user_id and selected role will always be correct,
        // though in case of backend changes
      }
      return false;
    },

    addAssignee() {
      this.item.assignees.push({ permission: 'employee' });
    },

    openLink(url) {
      const fullUrl = ['mailto:', 'tel:', 'http://', 'https://'].every(
        (prefix) => !url.includes(prefix),
      )
        ? `https://${url}`
        : url;
      window.open(fullUrl, '_blank');
    },

    setAssignee(selectedAssignee, index) {
      const assignee = clone(selectedAssignee);

      if (assignee) {
        const { permission } = this.item.assignees[index];
        assignee.user_id = assignee.id;
        this.item.assignees[index] = assignee;
        if (assignee.client_id) {
          assignee.permission = 'client';
        } else {
          assignee.permission = permission;
        }
        this.item.assignees.splice(index, 1, assignee);
      } else {
        this.item.assignees[index] = {};
      }
    },

    setPermission(value, index) {
      this.item.assignees[index].permission = value;
    },
  },
};
</script>

<style scoped></style>
