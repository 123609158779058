<template>
  <div>
    <v-row v-for="(assignee, index) of assignees" :key="assignee.user_id" dense>
      <v-col cols="12" sm="6" md="5">
        <UserAutocomplete
          v-model="assignee.user_id"
          :disabled="assignee.permission === 'admin' || disabled"
          :initial-item="getUserFromAssignee(assignee)"
          @update:initial-item="setAssignee($event, index)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="assignee.permission"
          :items="projectPermissions"
          :disabled="assignee.permission === 'admin' || disabled"
          :label="$t('general.role')"
        />
      </v-col>

      <v-col v-if="!disabled" cols="12" sm="6" md="4">
        <v-select
          :value="assignee.message_on_issue ? assignee.language : ''"
          :append-outer-icon="$vuetify.breakpoint.mdAndUp ? 'clear' : ''"
          :label="$t('projects.issue_status_and_comment_notifications')"
          :items="issueNotificationOptions"
          :disabled="disabled"
          item-value="language"
          @change="setNotificationOptions($event, assignee)"
          @click:append-outer="removeAssignee(assignee, index)"
        />
      </v-col>

      <template v-if="$vuetify.breakpoint.mdAndDown && !disabled">
        <v-col cols="12" sm="6" class="d-flex justify-end align-center">
          <v-btn text @click="removeAssignee(assignee, index)">
            <v-icon left>clear</v-icon>
            {{ $t('general.controls.remove_user') }}
          </v-btn>
        </v-col>

        <v-col v-if="index < assignees.length - 1" class="my-4" cols="12">
          <v-divider />
        </v-col>
      </template>
    </v-row>

    <div v-if="!disabled" class="text-center mt-4">
      <v-btn text @click="addAssignee">
        <v-icon left>add</v-icon>
        {{ $t('projects.labels.add_user') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import clone from 'just-clone';
import UserAutocomplete from '@/components/autocompletes/UserAutocomplete';

export default {
  name: 'ProjectAssigneeMultiSelect',

  components: { UserAutocomplete },

  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: () => null,
    },
  },

  data() {
    return {
      assignees: [],
      userSearchRoute: 'api/users/find/',
    };
  },

  computed: {
    ...mapGetters('projects', ['projectPermissions']),

    issueNotificationOptions() {
      return [
        { text: this.$t('projects.dont_send'), message_on_issue: false, language: '' },
        { text: this.$t('projects.send_in_lithuanian'), message_on_issue: true, language: 'lt' },
        { text: this.$t('projects.send_in_english'), message_on_issue: true, language: 'en' },
      ];
    },
  },

  watch: {
    assignees: {
      handler(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    },
  },

  created() {
    this.assignees = clone(this.value);
  },

  methods: {
    ...mapActions('projects', ['deleteProjectAssignee']),

    addAssignee() {
      this.assignees.push({
        user_id: null,
        permission: 'employee',
        message_on_issue: false,
        language: 'lt',
      });
    },

    setAssignee(user, index) {
      if (user) {
        const permission = user.clients?.length ? 'client' : this.assignees[index].permission;
        const assignee = {
          ...this.assignees[index],
          permission,
          user_id: user.id,
          personal_data: user.personal_data,
        };
        this.assignees.splice(index, 1, assignee);
      } else {
        this.assignees[index].user_id = null;
      }
    },

    removeAssignee(assignee, index) {
      if (assignee.id) {
        this.deleteProjectAssignee(assignee);
      }
      this.assignees.splice(index, 1);
    },

    setNotificationOptions(language, assignee) {
      this.$set(assignee, 'message_on_issue', !!language);
      this.$set(assignee, 'language', language);
    },

    getUserFromAssignee(assignee) {
      if (!assignee?.user_id) {
        return null;
      }
      return {
        id: assignee.user_id,
        personal_data: assignee.personal_data,
      };
    },

    updateInitialItem(user, index) {
      this.assignees[index].user_id = user?.id || null;
      this.assignees[index].personal_data = user?.personal_data || null;
    },
  },
};
</script>

<style scoped></style>
