<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ProjectForm
      :disabled="$store.getters.loading[`put:api/projects/${selectedProject.id}`]"
      :errors="projectValidationErrors"
      :project="selectedProject"
      @clear:errors="CLEAR_PROJECT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ProjectForm from '@/components/forms/ProjectForm';

export default {
  name: 'EditProject',

  components: { ProjectForm },

  computed: {
    ...mapState('projects', ['selectedProject', 'projectValidationErrors', 'projectFilterParams']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('projects/editProject', +to.params.projectId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute(to));
      });
  },

  methods: {
    ...mapActions('projects', ['updateProject']),
    ...mapMutations('projects', ['CLEAR_PROJECT_VALIDATION_ERRORS']),

    onSave(project) {
      this.updateProject(project).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute(this.$route));
    },
  },
};
</script>
